import Vue from 'vue'
import Vuex from 'vuex'

import Config from '../config.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    config: new Object(),
    questions_answered: new Array(),
    survey_answer: {
      answers: new Array()
    },
    survey_evaluation: new Array()
  },
  mutations: {
    //load config from server
    load_config(state) {
      state.config = Config
    },
    //append answers
    survey_answer_append(state, payload) {
      for (let answer of payload.answers) {
        state.survey_answer.answers.push(answer)
      }
      if (
        state.questions_answered.find(
          (element) => element == payload.question
        ) == null
      ) {
        state.questions_answered.push(payload.question)
      }
    },
    //remove all answers belonging to a question
    survey_answer_pop(state, payload) {
      state.survey_answer.answers = state.survey_answer.answers.filter(
        (answer) => {
          return answer.question_id != payload
        }
      )
      state.questions_answered = state.questions_answered.filter((answer) => {
        return answer != payload
      })
    },
    //reset all survey variables
    survey_reset(state) {
      state.questions_answered = new Array()
      state.survey_answer.answers = new Array()
    }
  },
  actions: {},
  modules: {}
})
