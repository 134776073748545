export default {
  title: 'jf24',
  full_title: 'Jugendforum 2024',
  phase: 1,
  projectrgoup_with_label: 10,

  prod_host: 'https://leonberg.jugendforum.digital',

  preprod_api_host: 'https://api.test.leonberg.jugendforum.digital',
  prod_api_host: 'https://api.leonberg.jugendforum.digital',

  survey: {
    warn_after_skipped_questions: 2,
    description:
      'Diese Umfrage ist Teil des Jugendforums 2023. Mit deiner Stimme, kannst du auf Probleme in Leonberg aufmerksamen machen und Wünsche und Verbesserungsvorschläge einbringen.'
  },

  matomo: {
    enabled: true,
    host: 'https://matomo.jgd.tools',
    siteId: 3,
    trackerFileName: 'matomo',
    domains: 'leonberg.jugendforum.digital'
  },

  api_host() {
    if (window.location.origin == this.prod_host) {
      return this.prod_api_host
    } else {
      return this.preprod_api_host
    }
  }
}
