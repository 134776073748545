<template>
  <div id="app">
    <CookiePopup
      :show="cookiePopup"
      @close="
        () => {
          this.cookiePopup = false
        }
      "
    />
    <router-view
      @showCookieSettings="
        () => {
          this.cookiePopup = true
        }
      "
    />
  </div>
</template>

<script>
  import Config from './config.js'
  import CookiePopup from './views/cookiePopup.vue'
  export default {
    components: {
      CookiePopup
    },
    data() {
      return {
        cookiePopup: false
      }
    },
    created() {
      this.$store.commit('load_config')
      /* eslint-disable no-console */
      console.log(`Using backend from ${Config.api_host()}`)
      if (!localStorage.cookieSettings) {
        this.cookiePopup = true
      }
    }
  }
</script>

<style lang="scss">
  @import url('#{$STATIC_HOST}/fonts/inter/inter.css');
  @import url('#{$STATIC_HOST}/fonts/spock/spock.css');
  @import 'main.scss';

  body {
    margin: 0;
  }

  #app {
    font-family: Inter;
    text-align: center;
    color: #2c3e50;
  }

  button {
    border: none;
    background: none;
  }
</style>
