<template lang="html">
  <div id="cookie_popup">
    <transition name="BackgroundTransition">
      <div id="background" v-if="show"></div>
    </transition>
    <transition name="modalTransition">
      <div id="modal" v-if="show">
        <!-- close button -->
        <button
          type="button"
          name="button"
          id="discard_button"
          @click="discard"
        >
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="26.0956"
              height="3.05753"
              rx="1.52877"
              transform="matrix(0.71003 0.704171 -0.71003 0.704171 2.1709 0)"
              fill="#CECECE"
            />
            <rect
              width="26.0956"
              height="3.05753"
              rx="1.52877"
              transform="matrix(-0.71003 0.704171 -0.71003 -0.704171 20.6992 2.32422)"
              fill="#CECECE"
            />
          </svg>
        </button>

        <h2 id="cookie-title">Cookie-Optionen</h2>

        <!-- checkbox #1 -->
        <div class="container" style="margin-bottom: 40px">
          <span class="checkmark-disabled">
            <svg
              id="check_indicator_disabled"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.74435 17.5952L17.2111 6.4726C17.6557 6.00006 18.3993 5.97747 18.8718 6.42215C19.3444 6.86682 19.367 7.61037 18.9223 8.0829L7.65014 20.0614C7.20547 20.5339 6.46192 20.5565 5.98938 20.1118C5.98204 20.1049 5.9748 20.0979 5.96767 20.0909L0.855864 15.2805C0.383327 14.8358 0.360739 14.0922 0.805413 13.6197C1.25009 13.1472 1.99363 13.1246 2.46617 13.5693L6.74435 17.5952Z"
                fill="white"
              />
            </svg>
          </span>
          <p id="label">Notwendige Cookies</p>
          <p id="description">Diese Cookies sind nicht deaktivierbar</p>
        </div>

        <!-- checkbox #2 -->
        <label class="container" style="margin-bottom: 40px">
          <input
            type="checkbox"
            v-model="cookieSettings.matomo"
            name="matomo"
          />
          <span class="checkmark">
            <svg
              id="check_indicator"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.74435 17.5952L17.2111 6.4726C17.6557 6.00006 18.3993 5.97747 18.8718 6.42215C19.3444 6.86682 19.367 7.61037 18.9223 8.0829L7.65014 20.0614C7.20547 20.5339 6.46192 20.5565 5.98938 20.1118C5.98204 20.1049 5.9748 20.0979 5.96767 20.0909L0.855864 15.2805C0.383327 14.8358 0.360739 14.0922 0.805413 13.6197C1.25009 13.1472 1.99363 13.1246 2.46617 13.5693L6.74435 17.5952Z"
                fill="white"
              />
            </svg>
          </span>
          <p id="label">Statistik-Cookies (Matomo)</p>
          <p id="description">
            Mithilfe der Statistik-Cookies wollen wir verstehen, wie unsere
            Seite genutzt wird, um sie weiter zu optimieren. Mehr Informationen
            findest du in unseren
            <a href="/datenschutz">Datenschutzhinweisen.</a>
          </p>
        </label>

        <p id="notice">Du kannst deine Einwilligung jederzeit widerrufen.</p>

        <!-- buttons -->
        <button
          type="button"
          name="button"
          class="primary_button"
          id="allow_all"
          @click="allow_all"
        >
          Alles akzeptieren & Speichern
        </button>

        <button
          type="button"
          name="button"
          class="secondary_button"
          id="save"
          @click="save"
          style="margin-bottom: 38px"
        >
          Speichern
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        cookieSettings: {
          matomo: false
        }
      }
    },
    watch: {
      /* The display value is set to none after the animation has finished so that elements
      such as buttons of the parent object can be used */
      show: function (val) {
        var cookiePopup = document.getElementById('cookie_popup')
        if (val) {
          cookiePopup.style.display = 'block'
        } else {
          setTimeout(() => {
            cookiePopup.style.display = 'none'
          }, 500)
        }
      }
    },
    methods: {
      configureMatomo() {
        if (this.cookieSettings.matomo) {
          window._paq.push(['rememberConsentGiven'])
        } else {
          window._paq.push(['forgetConsentGiven'])
        }
      },
      save() {
        localStorage.cookieSettings = JSON.stringify(this.cookieSettings)
        this.configureMatomo()
        this.$emit('close')
      },
      allow_all() {
        localStorage.cookieSettings = JSON.stringify({
          matomo: true
        })
        window._paq.push(['rememberConsentGiven'])
        this.$emit('close')
      },
      discard() {
        this.$emit('close')
      }
    },
    created() {
      if (localStorage.cookieSettings) {
        this.cookieSettings = JSON.parse(localStorage.cookieSettings)
      }
    },
    mounted() {
      var cookiePopup = document.getElementById('cookie_popup')
      if (this.show) {
        cookiePopup.style.display = 'block'
      } else {
        setTimeout(() => {
          cookiePopup.style.display = 'none'
        }, 500)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../main.scss';
  #cookie_popup {
    position: fixed;
    display: none;
    width: 100vw;
    height: 100vh;
    z-index: 5;
  }

  .BackgroundTransition-enter-active,
  .BackgroundTransition-leave-active {
    transition: opacity 0.5s ease-in-out;
  }
  .BackgroundTransition-enter,
  .BackgroundTransition-leave-to {
    opacity: 0;
  }
  .BackgroundTransition-enter-to,
  .BackgroundTransition-leave {
    opacity: 1;
  }

  #background {
    position: fixed;
    z-index: 4;
    top: 0px;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.6);
  }

  #modal {
    position: fixed;
    overflow: scroll;
    z-index: 4;
    width: 553px;
    max-height: 675px;
    margin-top: 10%;
    bottom: 0px;
    border-radius: 20px;
    background: $main_color;
  }

  #seperator {
    position: relative;
    width: calc(100% - 136px);
    height: 0px;
    border: 1px solid #aaa7b5;
    margin-left: 68px;
  }

  #notice {
    position: relative;
    margin: 0px;
    margin-top: 16px;
    margin-bottom: 32px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #aaa7b5;
  }

  //buttons

  button {
    position: relative;
    margin-left: 69px;
    margin-right: 69px;
    margin-bottom: 18px;
    left: 0px;
    width: 416px;
    height: 52px;
    padding: 0px;
  }

  #discard_button {
    position: absolute;
    top: 32px;
    right: 32px;
    left: auto;
    margin: 0;
    width: auto;
    height: auto;
  }

  #cookie-title {
    margin-top: 56px;
    margin-left: 68px;
    margin-bottom: 50px;
    text-align: left;
    color: $text-color !important;
  }

  //checkbox

  .container {
    display: block;
    position: relative;
    padding-left: 126px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  #label {
    font-family: Inter;
    font-style: bold;
    font-weight: bold;
    font-size: 16px;
    color: #3d3d3d;

    text-align: left;
    margin: 0px;
    margin-right: 68px;
  }

  #description {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #aaa7b5;

    text-align: left;
    margin: 0px;
    margin-right: 68px;
    a {
      color: #aaa7b5;
    }
  }

  .container input {
    display: none;
  }

  .container input:checked ~ .checkmark {
    background-color: $accent-color;
    #check_indicator {
      display: block;
    }
  }

  #check_indicator {
    display: none;
    margin-top: 3px;
    margin-left: 7px;
  }

  #check_indicator_disabled {
    margin-top: 3px;
    margin-left: 2px;
  }

  .checkmark {
    position: absolute;
    left: 68px;
    height: 32px;
    width: 32px;
    border-radius: 10px;
    border: 2px solid $accent-color;
    background-color: rgba($accent-color, 0.1);
  }

  .checkmark-disabled {
    position: absolute;
    left: 68px;
    height: 32px;
    width: 32px;
    border-radius: 10px;
    border: 2px solid $light-grey;
    background-color: $light-grey;
  }

  //Desktop
  @media (min-width: 765px) {
    #modal {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-top: 0px;
      height: 591px;
    }

    .modalTransition-enter-active,
    .modalTransition-leave-active {
      transition: transform 0.5s ease, opacity 0.5s ease-in-out;
    }
    .modalTransition-enter,
    .modalTransition-leave-to {
      transform: translate(-50%, 50vh) !important;
    }
    .modalTransition-enter-to,
    .modalTransition-leave {
      transform: translate(-50%, -50%) !important;
    }
  }

  //Mobile
  @media (max-width: 765px) {
    #modal {
      width: 100vw;
      height: 90%;
      max-height: 600px;
      margin-top: 10%;
      bottom: 0px;
      border-radius: 20px 20px 0px 0px;
    }
    .modalTransition-enter-active,
    .modalTransition-leave-active {
      transition: transform 0.5s ease, opacity 0.5s ease-in-out;
    }
    .modalTransition-enter,
    .modalTransition-leave-to {
      transform: translateY(100%) !important;
    }
    .modalTransition-enter-to,
    .modalTransition-leave {
      transform: translateY(0%) !important;
    }

    .container {
      padding-left: 88px;
    }

    #discard_button {
      position: absolute;
      top: 28px;
      right: 28px;
    }

    .checkmark {
      left: 28px;
    }

    .checkmark-disabled {
      left: 28px;
    }

    #cookie-title {
      margin-left: 28px;
    }

    #label {
      margin-right: 28px;
    }

    #description {
      margin-right: 28px;
    }

    #seperator {
      margin-left: 33px;
      width: calc(100% - 66px);
    }

    #notice {
      margin-left: 28px;
      margin-right: 28px;
    }

    //buttons

    button {
      width: calc(100% - 64px);
      margin-left: 32px;
      margin-right: 32px;
    }
  }
</style>
