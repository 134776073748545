import Vue from 'vue'
import VueRouter from 'vue-router'
import Config from '../config.js'

Vue.use(VueRouter)

var routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: () => import('../views/landingPage/landingPage.vue')
  },
  // {
  //   path: '/info',
  //   name: 'Info',
  //   component: () => import('../views/info.vue')
  // },
  {
    path: '/hilfe',
    name: 'Hilfe',
    component: () => import('../views/help/help.vue')
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: () => import('../views/privacy.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import('../views/imprint.vue')
  },
  {
    path: '/400',
    meta: { title: '400' },
    component: () => import('../views/errors/400.vue')
  },
  {
    path: '/401',
    meta: { title: '401' },
    component: () => import('../views/errors/401.vue')
  },
  {
    path: '/403',
    meta: { title: '403' },
    component: () => import('../views/errors/403.vue')
  },
  {
    path: '/500',
    meta: { title: '500' },
    component: () => import('../views/errors/500.vue')
  },
  {
    path: '/901',
    meta: { title: '901' },
    component: () => import('../views/errors/901.vue')
  },
  {
    path: '/902',
    meta: { title: '902' },
    component: () => import('../views/errors/902.vue')
  },
  {
    path: '/902',
    meta: { title: '902' },
    component: () => import('../views/errors/902.vue')
  },
  {
    path: '/903',
    meta: { title: '903' },
    component: () => import('../views/errors/903.vue')
  },
  {
    path: '/904',
    meta: { title: '904' },
    component: () => import('../views/errors/904.vue')
  },
  {
    path: '/905',
    meta: { title: '905' },
    component: () => import('../views/errors/905.vue')
  },
  {
    path: '/906',
    meta: { title: '906' },
    component: () => import('../views/errors/906.vue')
  },
  {
    path: '/907',
    meta: { title: '907' },
    component: () => import('../views/errors/907.vue')
  },
  {
    path: '/908',
    meta: { title: '908' },
    component: () => import('../views/errors/908.vue')
  },
  {
    path: '/909',
    meta: { title: '909' },
    component: () => import('../views/errors/909.vue')
  },
  {
    path: '/910',
    meta: { title: '910' },
    component: () => import('../views/errors/910.vue')
  },
  {
    path: '*',
    meta: { title: '404' },
    component: () => import('../views/errors/404.vue')
  }
]

if (Config.phase >= 1) {
  routes = routes.concat([
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import('../views/dashboard/dashboard.vue')
    },
    {
      path: '/umfrage/:id',
      name: 'Survey',
      meta: { title: 'Umfrage' },
      component: () => import('../views/survey/Survey.vue'),
      props: true
    },
    {
      path: '/umfrage/:id/ergebnisse',
      name: 'SurveyResult',
      component: () => import('../views/survey/Results.vue'),
      props: true
    },
    {
      path: '/willkommen',
      name: 'Wellcome',
      component: () => import('../views/register/welcome.vue'),
      props: true
    },
    {
      path: '/registrieren',
      name: 'Register',
      component: () => import('../views/register/Register.vue'),
      props: true
    },
    {
      path: '/anmelden',
      name: 'Login',
      component: () => import('../views/login/login.vue'),
      props: true
    },
    {
      path: '/projektgruppen',
      name: 'Overview',
      component: () => import('../views/overview/overview.vue'),
      props: true
    },
    {
      path: '/event/anmelden',
      name: 'Event_anmelden',
      component: () =>
        import('../views/event_registration/EventRegistration.vue'),
      props: true
    },
    {
      path: '/projektgruppe/:project_group',
      name: 'Project_group',
      meta: { title: 'Projektgruppe' },
      component: () =>
        import('../views/project_group_detail/project_group_detail.vue'),
      props: true
    },
    {
      path: '/account/',
      name: 'account',
      component: () => import('../views/account/account/Account.vue'),
      props: true
    },
    {
      path: '/account/email-verifizieren',
      name: 'email_bestaetigen_anfordern',
      component: () =>
        import(
          '../views/account/email_bestaetigen_anfordern/email_bestaetigen.vue'
        ),
      props: true
    },
    {
      path: '/account/email-verifizieren/:mail/:token',
      name: 'email_bestaetigen',
      component: () =>
        import('../views/account/email_bestaetigen/email_bestaetigen.vue'),
      props: true,
      meta: {
        analyticsIgnore: true
      }
    },
    {
      path: '/account/passwort-zuruecksetzen',
      name: 'passwort_zuruecksetzen_anfordern',
      component: () =>
        import('../views/account/passwort_reset_anfordern/passwort_reset.vue'),
      props: true
    },
    {
      path: '/account/passwort-zuruecksetzen/:mail/:token',
      name: 'passwort_zuruecksetzen',
      component: () =>
        import('../views/account/passwort_reset/passwort_reset.vue'),
      props: true,
      meta: {
        analyticsIgnore: true
      }
    },
    {
      path: '/statistiken',
      name: 'Statistiken',
      component: () => import('../views/statistics/Statistics.vue'),
      props: true
    },
    {
      path: '/berichte',
      name: 'Berichte',
      component: () => import('../views/berichte/Berichte.vue'),
      props: true
    },
    {
      path: '/teilnahmezertifikat/fehler',
      name: 'certificate_not_found',
      component: () => import('../views/certificate/certificateNotFound.vue'),
      props: true
    }
  ])
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return to.hash
    } else {
      return { x: 0, y: 0 }
    }
  },
  beforeEach: (to, from, next) => {
    document.title = 'Jugendforum Leonberg'
    next()
  }
})

// router.beforeEach((to, from, next) => {
//   document.title = 'Jugendforum Leonberg'
//   next()
// })

export default router
